@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');

.font-handwriting {
  font-family: 'Caveat', cursive;
}

@layer components {
  .slider-input {
    @apply w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer opacity-70 transition-opacity duration-200;
  }

  .slider-input:hover {
    @apply opacity-100;
  }

  .slider-input::-webkit-slider-thumb {
    @apply appearance-none w-5 h-5 rounded-full bg-blue-500 cursor-pointer;
  }

  .slider-input::-moz-range-thumb {
    @apply w-5 h-5 rounded-full bg-blue-500 cursor-pointer border-0;
  }
}